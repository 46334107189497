import styled from "@emotion/styled";

const HeroText = styled.div`
  p {
    ${({ theme }) => theme.breakpoints.down("xs")} {
      font-size: 17px;
    }

    ${({ theme }) => theme.breakpoints.between("xs", "xl")} {
      font-size: 20px;
    }

    ${({ theme }) => theme.breakpoints.up("xl")} {
      font-size: 26px;
    }
  }
`;

export default HeroText;
