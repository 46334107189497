import styled from "@emotion/styled";
import isBrowser from "@/utils/isBrowser";

export default function LetTalkButton() {
  return (
    <a
      href="https://calendly.com/moebelleumbeau/first-consultation"
      target="_blank"
      rel="noreferrer"
    >
      <Button
        src={
          isBrowser() && location.href.indexOf("/en") > 0
            ? "/images/lettalk_en.png"
            : "/images/lettalk_de.png"
        }
        alt="Let Talk"
      />
    </a>
  );
}

const Button = styled.img`
  position: absolute;
  cursor: pointer;
  &:hover {
    transform: rotate(10deg);
  }
  ${({ theme }) => theme.breakpoints.down("lg")} {
    bottom: 16px;
    right: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    bottom: 36px;
    right: 40px;
  }
`;
