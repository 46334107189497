import styled from "@emotion/styled";

const DropdownTableBottom = styled.div`
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};

  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 15px;
  }

  ${({ theme }) => theme.breakpoints.between("xs", "lg")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    border-top: 2px solid ${({ theme }) => theme.palette.text};
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 26px;
    border-top: 3px solid ${({ theme }) => theme.palette.text};
  }
`;

export default DropdownTableBottom;
