import styled from "@emotion/styled";

const FormWithTextFormAgree = styled.label`
  display: flex;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    gap: 10px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    gap: 20px;
  }
`;

export default FormWithTextFormAgree;
