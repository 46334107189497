import styled from "@emotion/styled";

const HeroTitle = styled.h2`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 30px;
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 42px;
    margin-bottom: 30px;
  }
`;

export default HeroTitle;
