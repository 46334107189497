import styled from "@emotion/styled";

const HeroLeft = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 48%;
  }
`;

export default HeroLeft;
