import styled from "@emotion/styled";

const TextWithImagesLeft = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    display: contents;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 47%;
  }
`;

export default TextWithImagesLeft;
