import styled from "@emotion/styled";

const DropdownBlockColumn = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 80px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: 100px;
  }
`;

export default DropdownBlockColumn;
