import styled from "@emotion/styled";

const FormWithTextSocials = styled.div`
  display: flex;
  gap: 20px;

  svg {
    width: 28px;
    height: 28px;
  }
`;

export default FormWithTextSocials;
