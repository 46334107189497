import { css } from "@emotion/react";
import styled from "@emotion/styled";

const dynamicStyle = ({ bgColor, active, theme }) => css`
  background-color: ${bgColor};

  ${active
    ? `
    ${theme.breakpoints.down("lg")} {
      display: flex;
    }`
    : `
    ${theme.breakpoints.down("lg")} {
      display: none;
    }
  `}
`;

const IntroLeftWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    padding: 13px 20px 20px;
  }

  ${({ theme }) => theme.breakpoints.between("xs", "lg")} {
    padding: 11px 20px 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 50%;
  }

  ${dynamicStyle}
`;

export default IntroLeftWrapper;
