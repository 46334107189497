import { css } from "@emotion/react";
import styled from "@emotion/styled";

const dynamicStyle = ({ active }) => css`
  ${active
    ? `
    position: fixed;
  `
    : `
  position: absolute;
  `}
`;

const HeaderBurger = styled.button`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    z-index: 2;
    top: 0;
    right: 0;
    display: flex;
    padding: 20px;

    ${dynamicStyle}
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: none;
  }
`;

export default HeaderBurger;
