import styled from "@emotion/styled";

const FormWithTextTitle = styled.h2`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 30px;
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 38px;
    margin-bottom: 40px;
  }
`;

export default FormWithTextTitle;
