import { useState } from "react";

import isBrowser from "@/utils/isBrowser";
import Container from "@/components/Container";
import { Languages, LanguagesItem } from "@/components/LanguagesBlock/index";
import Text from "@/components/Text";

import {
  IntroImage,
  IntroLeft,
  IntroLink,
  IntroLogo,
  IntroNavigation,
  IntroNavigationItem,
  IntroRight,
  IntroRightBottom,
  IntroTitle,
  IntroContent,
  IntroText,
  IntroBurgerButton,
  IntroLeftWrapper,
  IntroWrapper,
  LetTalkButton,
} from "@/components/Intro/index";

const Intro = ({
  description: { childMarkdownRemark },
  link: { text, url },
  links,
  image: { image },
  logo,
  title,
  backgroundColorNavigation: bgColorNav,
  urlsArray,
}) => {
  const [active, setActive] = useState(false);

  const handleCloseMenu = () => {
    setTimeout(() => setActive(false), 300);
  };

  return (
    <IntroWrapper bgColor={bgColorNav}>
      <Container disablePaddings>
        <IntroContent>
          <IntroLeftWrapper active={active} bgColor={bgColorNav}>
            <IntroLeft>
              <IntroNavigation>
                {links.map((link) => {
                  return (
                    <IntroNavigationItem onClick={handleCloseMenu} key={link.id} to={link.url}>
                      {link.text}
                    </IntroNavigationItem>
                  );
                })}
              </IntroNavigation>
              {isBrowser() && (
                <Languages variation="header">
                  {Object.entries(urlsArray).map((el) => (
                    <LanguagesItem
                      key={el[0]}
                      active={location.pathname === `${el[1] === "/" ? el[1] : `${el[1]}/`}`}
                      to={el[1]}
                    >
                      {el[0]}
                    </LanguagesItem>
                  ))}
                </Languages>
              )}
            </IntroLeft>
          </IntroLeftWrapper>
          <IntroRight>
            <IntroBurgerButton aria-label="menu" active={active} onClick={() => setActive(!active)}>
              {active ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27.42"
                  height="27.42"
                  viewBox="0 0 28.833 28.834"
                >
                  <g
                    id="Group_225"
                    data-name="Group 225"
                    transform="translate(-8123.793 23412.208)"
                  >
                    <line
                      id="Line_20"
                      data-name="Line 20"
                      x2="27.419"
                      y2="27.419"
                      transform="translate(8124.5 -23411.5)"
                      fill="none"
                      stroke="#000"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_92"
                      data-name="Path 92"
                      d="M0,0,27.419,27.419"
                      transform="translate(8124.5 -23384.082) rotate(-90)"
                      fill="none"
                      stroke="#000"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27.42"
                  height="18.983"
                  viewBox="0 0 27.42 20.983"
                >
                  <g id="Group_352" data-name="Group 352" transform="translate(-345.5 -7.5)">
                    <line
                      id="Line_5"
                      data-name="Line 5"
                      x2="27.42"
                      transform="translate(345.5 8.5)"
                      fill="none"
                      stroke="#FFF"
                      strokeWidth="2"
                    />
                    <line
                      id="Line_7"
                      data-name="Line 7"
                      x2="27.42"
                      transform="translate(345.5 17.992)"
                      fill="none"
                      stroke="#FFF"
                      strokeWidth="2"
                    />
                    <line
                      id="Line_8"
                      data-name="Line 8"
                      x2="27.42"
                      transform="translate(345.5 27.483)"
                      fill="none"
                      stroke="#FFF"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              )}
            </IntroBurgerButton>
            <IntroLogo useImage {...logo} />
            <div
              style={{
                position: "relative",
              }}
            >
              <IntroImage {...image} />
              <LetTalkButton />
            </div>
            <IntroRightBottom>
              <IntroTitle>{title}</IntroTitle>
              <IntroText>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: childMarkdownRemark.html,
                  }}
                />
              </IntroText>
              <IntroLink to={url}>{text}</IntroLink>
            </IntroRightBottom>
          </IntroRight>
        </IntroContent>
      </Container>
    </IntroWrapper>
  );
};

export default Intro;
