import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Text from "../Text";

const dynamicStyle = ({ variation, theme }) => css`
  ${variation === "desc"
    ? `
    font-size: 15px;

    ${theme.breakpoints.down("lg")} {
      margin-top: 10px;
    }

    ${theme.breakpoints.up("lg")} {
      margin-top: 20px;
    }
  `
    : `
  ${theme.breakpoints.up("lg")} {
    margin-bottom: 40px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 17px;
  }

  ${theme.breakpoints.between("xs", "2lg")} {
    font-size: 20px;
  }

  ${theme.breakpoints.between("2lg", "xl")} {
    font-size: 23px;
    line-height: 27px;
  }

  ${theme.breakpoints.up("xl")} {
    font-size: 28px;
    line-height: 33px;
  }
  `}
`;

const PortfolioText = styled(Text)`
  white-space: pre-wrap;

  ${dynamicStyle}
`;

export default PortfolioText;
