import Container from "@/components/Container";
import Text from "@/components/Text";

import {
  TextWithImagesContent,
  TextWithImagesImage,
  TextWithImagesImageWrapper,
  TextWithImagesLeft,
  TextWithImagesRight,
  TextWithImagesText,
  TextWithImagesTitle,
  TextWithImagesWrapper,
  TextWithImagesBlockTexts,
} from "@/components/TextWithImages/index";

const TextWithImages = ({ images, title, textTop, textBottom, backgroundColor: bgColor }) => {
  return (
    <TextWithImagesWrapper bgColor={bgColor}>
      <Container>
        <TextWithImagesContent>
          <TextWithImagesLeft>
            <TextWithImagesTitle>{title}</TextWithImagesTitle>
            <TextWithImagesBlockTexts>
              {textTop ? (
                <TextWithImagesText order="-2">
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: textTop.childMarkdownRemark.html,
                    }}
                  />
                </TextWithImagesText>
              ) : null}
              {textBottom ? (
                <TextWithImagesText order="-1">
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: textBottom.childMarkdownRemark.html,
                    }}
                  />
                </TextWithImagesText>
              ) : null}
            </TextWithImagesBlockTexts>
          </TextWithImagesLeft>
          <TextWithImagesRight>
            {images.map(({ description, image: { title: imageTitle, ...image } }, idx) => {
              return (
                <TextWithImagesImageWrapper order={idx - 2} key={imageTitle}>
                  <TextWithImagesImage {...image} />
                  {description ? <p>{description?.description}</p> : null}
                </TextWithImagesImageWrapper>
              );
            })}
          </TextWithImagesRight>
        </TextWithImagesContent>
      </Container>
    </TextWithImagesWrapper>
  );
};

export default TextWithImages;
