import { css } from "@emotion/react";

import styled from "@emotion/styled";

const dynamicStyle = ({ active, theme }) => css`
  ${active
    ? `
    ${theme.breakpoints.down("lg")} {
      display: flex;
    }
    `
    : `

    ${theme.breakpoints.down("lg")} {
      display: none
    }
    `}
`;

const HeaderNavigation = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 100%;
    height: 100%;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.palette.section.rose};
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    padding: 13px 20px 20px;
  }

  ${({ theme }) => theme.breakpoints.between("xs", "lg")} {
    padding: 11px 20px 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    margin-top: 8px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    margin-top: 10px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    margin-top: 8px;
  }

  ${dynamicStyle}
`;

export default HeaderNavigation;
