import { css } from "@emotion/react";
import styled from "@emotion/styled";

const dynamicStyle = ({ type }) => css`
  ${type === "image/svg+xml"
    ? `
    width: 100%;
  `
    : ""}
`;

const PortfolioImage = styled.div`
  display: flex;
  flex-direction: column;

  img,
  svg {
    width: 100%;
    height: 100%;
  }

  ${dynamicStyle}
`;

export default PortfolioImage;
