import styled from "@emotion/styled";

const DropdownContentWrapper = styled.div`
  ${({ variation, active }) => !variation || `max-height: ${active ? "10000px " : "0"}`};
  overflow: hidden;
  transition: ${({ active }) =>
    active ? "1s cubic-bezier(0, 0, 0.4, 0)" : "1s cubic-bezier(0, 1, 0, 1)"};
`;

export default DropdownContentWrapper;
