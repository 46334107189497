import { css } from "@emotion/react";
import styled from "@emotion/styled";

const dynamicStyle = ({ bgColor }) => css`
  background-color: ${bgColor};
`;

const PortfolioItemWrapper = styled.div`
  cursor: pointer;
  ${dynamicStyle}
`;

export default PortfolioItemWrapper;
