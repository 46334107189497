import styled from "@emotion/styled";

const IntroTitle = styled.h2`
  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 45px;
  }

  ${({ theme }) => theme.breakpoints.between("xs", "lg")} {
    font-size: 55px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-top: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    font-size: 60px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 82px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 93px;
  }
`;

export default IntroTitle;
