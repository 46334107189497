import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Link from "../Link";

const dynamicStyle = ({ variation, theme }) => css`
  ${variation === "block"
    ? `
    ${theme.breakpoints.down("lg")} {
      order: -1;
      align-self: center;
    }

    ${theme.breakpoints.up("lg")} {
      max-width: 377px;
    }
  `
    : `
    ${theme.breakpoints.up("lg")} {
      max-width: 441px;
    }
    `}
`;

const DropdownButton = styled(Link)`
  display: flex;
  max-width: fit-content;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.buttonBg};

  ${({ theme }) => theme.breakpoints.down("lg")} {
    max-width: 286px;
    padding: 10px 20px;
    font-size: 30px;
    border: 2px solid ${({ theme }) => theme.palette.text};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 20px 20px;
    font-size: 42px;
    border: 3px solid ${({ theme }) => theme.palette.text};
  }

  ${dynamicStyle}
`;

export default DropdownButton;
