import { css } from "@emotion/react";
import styled from "@emotion/styled";

const dynamicStyle = ({ bgColor }) => css`
  ${bgColor ? `background-color: ${bgColor}` : ""}
`;

const DropdownWrapper = styled.section`
  cursor: pointer;

  ${dynamicStyle}
`;

export default DropdownWrapper;
