import styled from "@emotion/styled";

const FormWithTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 20px;
  white-space: pre-wrap;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    order: 1;
    margin-top: 70px;
  }
`;

export default FormWithTextInfo;
