import { css } from "@emotion/react";

const font = css`
  @font-face {
    font-family: "suisse";
    src: url("/fonts/SuisseIntl-Book-WebM.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "suisse";
    src: url("/fonts/SuisseIntl-Medium.otf") format("otf");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "suisse_intl";
    src: url("https://db.onlinewebfonts.com/t/13ab13418f633c1b0516fed6e30bedbc.eot");
    src: url("https://db.onlinewebfonts.com/t/13ab13418f633c1b0516fed6e30bedbc.eot?#iefix")
        format("embedded-opentype"),
      url("https://db.onlinewebfonts.com/t/13ab13418f633c1b0516fed6e30bedbc.woff2") format("woff2"),
      url("https://db.onlinewebfonts.com/t/13ab13418f633c1b0516fed6e30bedbc.woff") format("woff"),
      url("https://db.onlinewebfonts.com/t/13ab13418f633c1b0516fed6e30bedbc.ttf") format("truetype"),
      url("https://db.onlinewebfonts.com/t/13ab13418f633c1b0516fed6e30bedbc.svg#Suisse Int'l")
        format("svg");
  }
`;

export default font;
