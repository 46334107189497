import styled from "@emotion/styled";
import { css } from "@emotion/react";

const dynamicStyle = ({ disablePaddings, theme }) => css`
  ${disablePaddings
    ? ""
    : `
    ${theme.breakpoints.down("lg")} {
      padding-left: 20px;
      padding-right: 20px;
    }

    ${theme.breakpoints.up("lg")} {
      padding-left: 40px;
      padding-right: 40px;
    }
    `}
`;

const Container = styled.div`
  width: 100%;
  max-width: 1472px;
  margin-left: auto;
  margin-right: auto;
  ${dynamicStyle};
`;

export default Container;
