import styled from "@emotion/styled";

const FormWithTextContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    gap: 40px;
  }
`;

export default FormWithTextContent;
