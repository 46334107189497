import FormWithTextContent from "./FormWithTextContent";
import FormWithTextForm from "./FormWithTextForm";
import FormWithTextFormAgree from "./FormWithTextFormAgree";
import FormWithTextFormButton from "./FormWithTextFormButton";
import FormWithTextFormItem from "./FormWithTextFormItem";
import FormWithTextInfo from "./FormWithTextInfo";
import FormWithTextRight from "./FormWithTextRight";
import FormWithTextSocials from "./FormWithTextSocials";
import FormWithTextTitle from "./FormWithTextTitle";
import FormWithTextWrapper from "./FormWithTextWrapper";
import LetTalkButton from "./LetTalkButton";

export {
  FormWithTextContent,
  FormWithTextForm,
  FormWithTextFormAgree,
  FormWithTextFormButton,
  FormWithTextFormItem,
  FormWithTextInfo,
  FormWithTextRight,
  FormWithTextSocials,
  FormWithTextTitle,
  FormWithTextWrapper,
  LetTalkButton,
};
