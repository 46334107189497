import styled from "@emotion/styled";

const TextWithImagesImageWrapper = styled.div`
  font-size: 15px;
  display: flex;
  gap: 15px;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    order: ${({ order }) => order};
    margin-bottom: 20px;
  }
`;

export default TextWithImagesImageWrapper;
