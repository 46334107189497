import styled from "@emotion/styled";

const TextWithImagesText = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    order: ${({ order }) => order};
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 17px;
  }

  ${({ theme }) => theme.breakpoints.between("xs", "2lg")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("2lg", "xl")} {
    font-size: 23px;
    line-height: 27px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 28px;
    line-height: 33px;
  }
`;

export default TextWithImagesText;
