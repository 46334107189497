import styled from "@emotion/styled";

const PortfolioItemTitle = styled.h2`
  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 26px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 42px;
  }
`;

export default PortfolioItemTitle;
