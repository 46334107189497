import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Link from "../Link";

const dynamicStyle = ({ active, theme, disablePadding }) => css`
  ${active
    ? `
      text-underline-position: under;
      text-decoration: underline ${theme.palette.text};
  `
    : `
    &:hover {
      text-underline-position: under;
      text-decoration: underline ${theme.palette.text};
    }
  `}

  ${disablePadding
    ? ""
    : `
    padding: 10px;
  `}
`;

const LanguagesItem = styled(Link)`
  font-size: 15px;

  ${dynamicStyle}
`;

export default LanguagesItem;
