import { css } from "@emotion/react";
import styled from "@emotion/styled";

const dynamicStyle = ({ variation, theme }) => css`
  ${variation === "textarea"
    ? `
    height: 138px;
    margin-top: 20px;
    margin-bottom: 20px;
    resize: none;
  `
    : ""}

  ${variation !== "checkbox"
    ? `
    ${theme.breakpoints.up("lg")} {
      width: 84%;
    }
    padding: 10px;
    color: ${theme.palette.text};

    &::placeholder {
      color: ${theme.palette.text};
    }

    `
    : `
      cursor: pointer;
      width: 25px;
      height: 25px;
    `}
`;

const FormWithTextFormItem = styled.input`
  border: 2px solid ${({ theme }) => theme.palette.text};

  ${dynamicStyle}
`;

export default FormWithTextFormItem;
