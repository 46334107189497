import styled from "@emotion/styled";

import Image from "../Image";

const DropdownImage = styled(Image)`
  ${({ type, theme, fullWidth }) =>
    type === "image/svg+xml"
      ? `
      ${
        fullWidth
          ? ""
          : `
      ${theme.breakpoints.down("sm")} {
        max-width: 184px;
        max-height: 178px;
      }
      `
      }


      ${theme.breakpoints.between("sm", "lg")} {
        width: 70%;
        height: 100%;
      }

      ${theme.breakpoints.down("xl")} {
        align-self: center;
      }

      ${
        fullWidth
          ? `
          width: 100%;
      `
          : ""
      }

      svg {
        width: 100%;
        height: 100%;

        // path,
        // rect,
        // polygon,
        // line  {
        //   stroke-dasharray: none;
        //   stroke-width: initial;
        // }
      }
  `
      : `
      ${theme.breakpoints.down("lg")} {
        max-width: 484px;
        align-self: center;
        object-fit: cover;
      }
  `}
`;

export default DropdownImage;
