import styled from "@emotion/styled";
import Link from "../Link";

const IntroNavigationItem = styled(Link)`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-left: 10px;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 32px;
  }

  ${({ theme }) => theme.breakpoints.between("xs", "lg")} {
    font-size: 40px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2lg")} {
    font-size: 57px;
  }

  ${({ theme }) => theme.breakpoints.between("2lg", "xl")} {
    font-size: 77px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    line-height: 90px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 82px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 93px;
  }
`;

export default IntroNavigationItem;
