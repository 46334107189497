import { css } from "@emotion/react";
import styled from "@emotion/styled";

const dynamicStyle = ({ active }) => css`
  ${active
    ? `
    position: fixed;
  `
    : `
  position: absolute;
  `}
`;

const IntroBurgerButton = styled.button`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: 20px;
    cursor: pointer;
    top: 0;
    right: 0;
    z-index: 2;

    ${dynamicStyle}
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: none;
  }
`;

export default IntroBurgerButton;
