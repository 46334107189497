import styled from "@emotion/styled";

const DropdownTableItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 15px;
  }

  ${({ theme }) => theme.breakpoints.between("xs", "lg")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 26px;
  }
`;

export default DropdownTableItem;
