import { css } from "@emotion/react";
import styled from "@emotion/styled";

const dynamicStyle = ({ variation }) => css`
  ${variation === "row-reverse" ? `flex-direction: row-reverse;` : ""}
`;

const DropdownBlock = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex-direction: column-reverse;
    margin-bottom: 40px;
    gap: 40px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: 100px;
  }

  ${dynamicStyle}
`;

export default DropdownBlock;
