import styled from "@emotion/styled";

const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: 20px 20px 0 20px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2lg")} {
    padding: 26px 40px 0 20px;
  }

  ${({ theme }) => theme.breakpoints.up("2lg")} {
    padding: 16px 40px 0 40px;
  }
`;

export default DropdownContent;
