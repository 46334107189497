import Container from "@/components/Container";
import Image from "@/components/Image";
import Text from "@/components/Text";

import {
  HeroLeft,
  HeroTitle,
  HeroWrapper,
  HeroRight,
  HeroText,
  HeroContent,
} from "@/components/Hero/index";

const Hero = ({ image: { image }, text: { childMarkdownRemark }, title, backgroundColor }) => {
  return (
    <HeroWrapper backgroundColor={backgroundColor} active>
      <Container>
        <HeroContent>
          <HeroLeft>
            <HeroTitle>{title}</HeroTitle>
            <HeroText>
              <Text
                dangerouslySetInnerHTML={{
                  __html: childMarkdownRemark.html,
                }}
              />
            </HeroText>
          </HeroLeft>
          <HeroRight>
            <Image {...image} />
          </HeroRight>
        </HeroContent>
      </Container>
    </HeroWrapper>
  );
};

export default Hero;
