import styled from "@emotion/styled";
import Image from "../Image";

const HeaderLogo = styled(Image)`
  margin-top: 20px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 116px;
    height: 39px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 242px;
    height: 81px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    padding-left: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding-left: 40px;
  }
`;

export default HeaderLogo;
