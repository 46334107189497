import styled from "@emotion/styled";
import NetlifyForm from "../NetlifyForm";

const FormWithTextForm = styled(NetlifyForm)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 20px;
    order: 1;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 22px;
    width: 47%;
  }
`;

export default FormWithTextForm;
