import styled from "@emotion/styled";

const FooterBottomNavigation = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex-direction: column;
  }

  a {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      padding: 10px;
    }

    &:hover {
      text-decoration-line: underline;
      text-underline-position: under;
      text-decoration-color: ${({ theme }) => theme.palette.text};
    }
  }
`;

export default FooterBottomNavigation;
