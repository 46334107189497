import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";
import { Global, ThemeProvider } from "@emotion/react";

import isBrowser from "@/utils/isBrowser";
import Seo from "@/components/Seo";
import theme from "@/theme";
import { Portfolio, Footer } from "@/sections";
import reboot from "@/styles/reboot";
import font from "@/styles/font";

import "destyle.css";

const Template = ({ pageContext: { baseUrl, seo, footer, urlsArray, cookies, ...props } }) => {
  if (isBrowser()) {
    window.scrollTo(0, 0);
  }
  return (
    <ThemeProvider theme={theme}>
      <Seo {...seo} />
      <Helmet defer={false} />
      <Global styles={[reboot, font]} />
      {isBrowser() && (
        <CookieConsent
          location="bottom"
          disableStyles
          containerClasses="cookies"
          buttonClasses="cookies-button"
          style={{
            backgroundColor:
              location.href.indexOf("/en") > 0
                ? cookies[1].backgroundColor
                : cookies[0].backgroundColor,
          }}
          buttonText={
            location.href.indexOf("/en") > 0 ? cookies[1].buttonText : cookies[0].buttonText
          }
        >
          <p>
            {location.href.indexOf("/en") > 0 ? cookies[1].text : cookies[0].text}
            <a href={location.href.indexOf("/en") > 0 ? cookies[1].link : cookies[0].link}>
              {location.href.indexOf("/en") > 0
                ? ` ${cookies[1].linkText}.`
                : ` ${cookies[0].linkText}.`}
            </a>
          </p>
        </CookieConsent>
      )}
      <Portfolio baseUrl={baseUrl} {...props} />
      <Footer {...footer} urlsArray={urlsArray} />
    </ThemeProvider>
  );
};

export default Template;
