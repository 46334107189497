import styled from "@emotion/styled";

const TextWithImagesTitle = styled.h2`
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: 25px;
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 23px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 30px;
    order: -2;
  }

  ${({ theme }) => theme.breakpoints.between("xs", "xl")} {
    font-size: 30px;
  }

  ${({ theme }) => theme.breakpoints.between("2lg", "xl")} {
    line-height: 35px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 38px;
    line-height: 44px;
  }
`;

export default TextWithImagesTitle;
