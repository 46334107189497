import styled from "@emotion/styled";

const DropdownBlockColumnItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    display: contents;
  }
`;

export default DropdownBlockColumnItem;
