import styled from "@emotion/styled";

const FormWithTextFormButton = styled.button`
  max-width: fit-content;
  padding: 10px 20px;
  border: 2px solid ${({ theme }) => theme.palette.text};
  background-color: ${({ theme }) => theme.palette.buttonBg};
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 22px;
  }
`;

export default FormWithTextFormButton;
