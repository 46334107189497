import styled from "@emotion/styled";
import Link from "../Link";

const PortfolioClose = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: 20px;

    & > svg {
      width: 27.42px;
      height: 27.42px;

      line,
      path {
        stroke-width: 2px;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 20px 40px;

    & > svg {
      width: 67px;
      height: 67px;

      line,
      path {
        stroke-width: 1px;
      }
    }
  }
`;

export default PortfolioClose;
