import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Link from "../Link";

const dynamicStyle = ({ active, theme }) => css`
  ${active &&
  `
  ${theme.breakpoints.up("lg")} {
    text-decoration-line: underline;
    text-underline-position: under;
    text-decoration-color: ${theme.palette.text};
  }
  `}
`;

const HeaderNavigationItem = styled(Link)`
  display: flex;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 32px;
  }

  ${({ theme }) => theme.breakpoints.between("xs", "lg")} {
    font-size: 40px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-inline: auto;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    padding: 20px 6px;
    font-size: 19px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 21px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    padding: 20px 8px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    padding: 20px 10px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    &:hover {
      text-decoration-line: underline;
      text-underline-position: under;
      text-decoration-color: ${({ theme }) => theme.palette.text};
    }
  }

  ${dynamicStyle}
`;

export default HeaderNavigationItem;
