import styled from "@emotion/styled";

const DropdownBlockList = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    order: ${({ order }) => order};
    margin-top: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 48%;
    margin-top: 30px;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 26px;
  }

  & > ul {
    list-style: inside;

    ${({ theme }) => theme.breakpoints.down("lg")} {
      margin-top: 20px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      margin-top: 30px;
    }
  }
`;

export default DropdownBlockList;
