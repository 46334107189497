import styled from "@emotion/styled";

const FooterInfoBigText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: flex-start;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 25px;
    font-size: 19px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 34px;
  }
`;

export default FooterInfoBigText;
