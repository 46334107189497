import styled from "@emotion/styled";

const PortfolioTitle = styled.h2`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 26px;
    margin-bottom: 20px;
    width: 80%;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 42px;
    margin-bottom: 60px;
  }
`;

export default PortfolioTitle;
