import styled from "@emotion/styled";

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-size: 15px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: 18px 20px;
    min-height: 400px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding: 20px 40px 10px 40px;
    min-height: 580px;
  }
`;

export default FooterContent;
