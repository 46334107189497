import styled from "@emotion/styled";

const DropdownTableHeadTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  svg {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      max-width: 68px;
      max-height: 74px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      width: 106px;
      height: 110px;
    }
  }
`;

export default DropdownTableHeadTop;
