/* eslint-disable no-console */
/* eslint-disable no-useless-escape */
/* eslint-disable no-alert */
import { useState } from "react";

import Image from "@/components/Image";
import Link from "@/components/Link";
import Container from "@/components/Container";

import {
  FormWithTextInfo,
  FormWithTextForm,
  FormWithTextRight,
  FormWithTextSocials,
  FormWithTextTitle,
  FormWithTextWrapper,
  FormWithTextFormItem,
  FormWithTextFormAgree,
  FormWithTextFormButton,
  FormWithTextContent,
  LetTalkButton,
} from "@/components/FormWithText/index";

const FormWithText = ({
  form: {
    agreeText,
    buttonSubmitText,
    emailPlaceholder,
    firstNamePlaceholder,
    lastNamePlaceholder,
    messagePlaceholder,
    phonePlaceholder,
    modalWindow,
  },
  info: {
    address: { address },
    email,
    phone,
    socials,
  },
  title,
  backgroundColor: bgColor,
}) => {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [check, setCheck] = useState(false);

  const changeValue = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  // eslint-disable-next-line consistent-return
  const validationForm = () => {
    const emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    const phoneRegexp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;

    if (
      formValues.firstName.length >= 3 &&
      formValues.firstName.length <= 50 &&
      formValues.lastName.length >= 3 &&
      formValues.lastName.length <= 50 &&
      emailRegexp.test(formValues.email) &&
      phoneRegexp.test(formValues.phone) &&
      check
    ) {
      return alert(modalWindow.text);
    }
  };

  const resetForm = () => {
    setFormValues({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    });
    setCheck(false);
  };

  return (
    <FormWithTextWrapper bgColor={bgColor}>
      <Container>
        <FormWithTextContent
          style={{
            position: "relative",
          }}
        >
          <FormWithTextForm formName="kontakt" postSubmit={resetForm} formValues={formValues}>
            <FormWithTextFormItem
              name="firstName"
              minLength="3"
              maxLength="50"
              pattern="^[a-zA-Z]+$"
              placeholder={firstNamePlaceholder}
              onChange={changeValue}
              value={formValues.firstName}
              required
            />
            <FormWithTextFormItem
              name="lastName"
              minLength="3"
              maxLength="50"
              pattern="^[a-zA-Z]+$"
              placeholder={lastNamePlaceholder}
              onChange={changeValue}
              value={formValues.lastName}
              required
            />
            <FormWithTextFormItem
              name="email"
              type="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              onChange={changeValue}
              value={formValues.email}
              placeholder={emailPlaceholder}
              required
            />
            <FormWithTextFormItem
              name="phone"
              type="tel"
              title="Gib deine Telefonnummer ein"
              minLength="8"
              maxLength="18"
              pattern="^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$"
              onChange={changeValue}
              value={formValues.phone}
              placeholder={phonePlaceholder}
              required
            />
            <FormWithTextFormItem
              onChange={changeValue}
              name="message"
              value={formValues.message}
              variation="textarea"
              as="textarea"
              placeholder={messagePlaceholder}
            />
            <FormWithTextFormAgree>
              <FormWithTextFormItem
                variation="checkbox"
                onClick={() => setCheck(!check)}
                checked={check}
                type="checkbox"
                required
              />
              <p>{agreeText}</p>
            </FormWithTextFormAgree>
            <FormWithTextFormButton
              type="submit"
              disabled={!validationForm}
              onClick={validationForm}
            >
              {buttonSubmitText}
            </FormWithTextFormButton>
            <LetTalkButton />
          </FormWithTextForm>
          <FormWithTextRight>
            <FormWithTextTitle>{title}</FormWithTextTitle>
            <FormWithTextInfo>
              <p>{address}</p>
              <div>
                <a href={`mailto:${email}`}>{email}</a>
                <br />
                <a href={`tel:${phone.replace(/[^0-9]/g, "")}`}>{phone}</a>
              </div>
              <FormWithTextSocials>
                {socials.map(({ id, icon, url }) => {
                  return (
                    <Link ariaLabel={url} to={url} key={id}>
                      <Image {...icon} />
                    </Link>
                  );
                })}
              </FormWithTextSocials>
            </FormWithTextInfo>
          </FormWithTextRight>
        </FormWithTextContent>
      </Container>
    </FormWithTextWrapper>
  );
};

export default FormWithText;
