import styled from "@emotion/styled";

const PortfolioBlockImages = styled.div`
  display: flex;
  justify-content: center;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("md")} {
    gap: 10px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "lg")} {
    gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    gap: 40px;
  }

  img,
  svg {
    width: 100%;
    height: 100%;
  }
`;

export default PortfolioBlockImages;
