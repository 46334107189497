import styled from "@emotion/styled";

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 5px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    position: relative;
    height: 100px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    height: 150px;
    padding-right: 14px;
    padding-left: 20px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-left: 20px;
    padding-right: 15px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding-right: 30px;
    height: 190px;
  }
`;

export default HeaderWrapper;
