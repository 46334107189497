import styled from "@emotion/styled";
import Languages from "../LanguagesBlock/Languages";

const HeaderNavigationBottom = styled(Languages)`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    order: 1;
    margin-top: auto;
    display: flex;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: none;
  }
`;

export default HeaderNavigationBottom;
