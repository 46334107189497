import styled from "@emotion/styled";

const OnlyTextContent = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 90px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: 180px;
    max-width: 1065px;
  }
`;

export default OnlyTextContent;
