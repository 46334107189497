const theme = {
  breakpoints: {
    values: {
      xs: 375,
      sm: 480,
      md: 768,
      lg: 992,
      "2lg": 1200,
      xl: 1280,
      "2xl": 1472,
    },

    up(key) {
      return `@media (min-width: ${this.values[key]}px)`;
    },
    down(key) {
      return `@media (max-width: ${this.values[key] - 0.02}px)`;
    },
    between(min, max) {
      return `@media (min-width: ${this.values[min]}px) and (max-width: ${
        this.values[max] - 0.02
      }px)`;
    },
  },
  palette: {
    footerBg: "#0062FF",
    section: {
      rose: "#FFCBE7",
      white: "#FFFFFF",
      yellow: "#E9FF43",
      gray: "#CCCDC7",
      lightCyan: "#DDFCEE",
      paleCyan: "#96DAF8",
      red: "#F07433",
      bluePurple: "#B5B6E4",
    },
    buttonBg: "#FFFFFF",
    text: "#000000",
  },
  typography: {
    rootFontSize: 16,
    pxToRem(value) {
      return `${(value / this.rootFontSize).toFixed(3)}rem`;
    },
    fontFamily: "suisse, Helvetica, sans-serif",
    fontWeight: {
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    fontSize: {
      title0: 103,
      title1: 88,
      title2: 42,
      title3: 38,
      title4: 34,
      subtitle: 24,
      link0: 42,
      link1: 21,
      text0: 34,
      textMain: 30,
      text2: 20,
      small: 15,
    },
    lineHeight: {
      "2xsm": "100%",
      xsm: "120%",
      sm: "150%",
      md: "170%",
      lg: "200%",
    },
  },

  mixins: {
    pxToPercent(value) {
      return (value / window.innerWidth) * 100;
    },
    pxToPercentFont(value) {
      return (value * 100) / window.innerWidth;
    },
  },
};

export default theme;
