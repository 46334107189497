import { css } from "@emotion/react";
import styled from "@emotion/styled";

const dynamicStyle = ({ variation, theme }) => css`
  & > a:first-child:after {
    content: "/";
    position: absolute;
    margin-left: 7px;
  }

  ${variation === "header"
    ? `
  ${theme.breakpoints.down("lg")} {
    margin-top: 30px;
    display: flex;
    margin-inline: auto;
  }

  ${theme.breakpoints.up("lg")} {
    display: none;
  }
  `
    : ""}

  ${variation === "footer"
    ? `
    ${theme.breakpoints.down("lg")} {
      display: none;
    }

    ${theme.breakpoints.up("lg")} {
      display: flex;
      align-items: center;
      & > a:first-child:after {
          margin-left: 7px;
      }
    }
  `
    : ""}

  ${variation === "intro"
    ? `
  display: flex;

  `
    : ""}
`;

const Languages = styled.div`
  color: ${({ theme }) => theme.palette.text};

  ${dynamicStyle}
`;

export default Languages;
