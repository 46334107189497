import { useEffect, useState } from "react";

import isBrowser from "@/utils/isBrowser";
import Container from "@/components/Container";
import Image from "@/components/Image";
import Text from "@/components/Text";

import {
  DropdownBlock,
  DropdownBlockLeft,
  DropdownBlockRight,
  DropdownBlockTitle,
  DropdownBottom,
  DropdownButton,
  DropdownContent,
  DropdownTable,
  DropdownTableBottom,
  DropdownTableItem,
  DropdownBlockColumn,
  DropdownTitle,
  DropdownBlockColumnItem,
  DropdownBlockList,
  DropdownTableHead,
  DropdownTableHeadTop,
  DropdownWrapper,
  DropdownImage,
  DropdownBlockText,
  DropdownContentWrapper,
} from "@/components/Dropdown/index";

const Dropdown = ({ title, icon, link, blocks, backgroundColor, hash }) => {
  const total = ({ tableItem }) => {
    return tableItem
      .reduce((sum, { price }) => {
        return sum + +price.replace(/[^\d]/g, "");
      }, 0)
      .toLocaleString("de-DE")
      .replace(".", "’");
  };

  // eslint-disable-next-line no-unneeded-ternary
  const [activeSection, setActiveSection] = useState(
    // eslint-disable-next-line no-unneeded-ternary
    isBrowser() && location.hash.startsWith(hash),
  );

  const handlerSection = () => {
    setActiveSection(!activeSection);

    if (isBrowser() && location.hash.includes(hash)) {
      location.hash = "";
    }
  };
  useEffect(() => {
    const el = document.getElementById(hash);
    if (el && location.hash.includes(hash)) {
      setTimeout(() => {
        el.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DropdownWrapper bgColor={backgroundColor} onClick={handlerSection}>
      <Container as="section" disablePaddings>
        <DropdownContent>
          <DropdownTitle id={hash}>{title}</DropdownTitle>
          <DropdownContentWrapper
            variation={title}
            active={activeSection || (isBrowser() && location.hash === hash)}
          >
            {blocks.map((block) => {
              return (
                <div key={block.id}>
                  {block.variation !== "tables-with-lists" ? (
                    <DropdownBlock variation={block.variation}>
                      <DropdownBlockLeft>
                        {block.title ? (
                          <DropdownBlockTitle>{block.title}</DropdownBlockTitle>
                        ) : null}
                        <DropdownBlockText>
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: block.text.childMarkdownRemark.html,
                            }}
                          />
                        </DropdownBlockText>
                        {block.tables
                          ? block.tables.map((table) => {
                              return (
                                <DropdownTable variation="small" key={table.id}>
                                  {table.tableItem.map(({ id, text, price }) => {
                                    return (
                                      <DropdownTableItem key={id}>
                                        <p>{text}</p>
                                        <p style={{ whiteSpace: "nowrap" }}>{price}</p>
                                      </DropdownTableItem>
                                    );
                                  })}
                                  <DropdownTableBottom>
                                    <p>TOTAL</p>
                                    <p>{`€ ${total(table)}`}</p>
                                  </DropdownTableBottom>
                                </DropdownTable>
                              );
                            })
                          : null}
                      </DropdownBlockLeft>
                      {block.image || block.link ? (
                        <DropdownBlockRight>
                          {block.image ? (
                            <DropdownImage
                              fullWidth={block.image[0]?.fullWidth}
                              type={block.image[0].image.file.contentType}
                              {...block.image[0].image}
                            />
                          ) : null}
                          {block.link ? (
                            <DropdownButton variation="block" to={block.link.url}>
                              {block.link.text}
                            </DropdownButton>
                          ) : null}
                        </DropdownBlockRight>
                      ) : null}
                    </DropdownBlock>
                  ) : null}

                  {block.variation === "tables-with-lists" && (
                    <DropdownBlockColumn>
                      <DropdownBlockTitle>{block.tableTitle}</DropdownBlockTitle>
                      <DropdownBlockColumnItem>
                        {block.tables
                          ? block.tables.map((table, idx) => {
                              return (
                                <DropdownTable order={(idx + 1) * 2 - 1} key={table.id}>
                                  <DropdownTableHead>
                                    <DropdownTableHeadTop>
                                      <DropdownBlockTitle>{table.title}</DropdownBlockTitle>
                                      <Image {...table.icon} />
                                    </DropdownTableHeadTop>
                                    <p>{table.subtitle}</p>
                                  </DropdownTableHead>
                                  {table.tableItem.map(({ id, text, price }) => {
                                    return (
                                      <DropdownTableItem key={id}>
                                        <p>{text}</p>
                                        <p style={{ whiteSpace: "nowrap" }}>{price}</p>
                                      </DropdownTableItem>
                                    );
                                  })}
                                  <DropdownTableBottom>
                                    <p>TOTAL</p>
                                    <p>{`€ ${total(table)}`}</p>
                                  </DropdownTableBottom>
                                </DropdownTable>
                              );
                            })
                          : null}
                      </DropdownBlockColumnItem>
                      <DropdownBlockColumnItem>
                        {block.lists.map(({ id, title: listTitle, item }, idx) => {
                          return (
                            <DropdownBlockList order={(idx + 1) * 2} key={id}>
                              <h3>{listTitle}</h3>
                              <ul>
                                {item.map((el) => (
                                  <li key={el}>{el}</li>
                                ))}
                              </ul>
                            </DropdownBlockList>
                          );
                        })}
                      </DropdownBlockColumnItem>
                    </DropdownBlockColumn>
                  )}
                </div>
              );
            })}
            {icon || link ? (
              <DropdownBottom>
                {icon ? <Image {...icon} /> : null}
                {link ? <DropdownButton to={link.url}>{link.text}</DropdownButton> : null}
              </DropdownBottom>
            ) : null}
          </DropdownContentWrapper>
        </DropdownContent>
      </Container>
    </DropdownWrapper>
  );
};

export default Dropdown;
