import styled from "@emotion/styled";

const PortfolioItemRight = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down("md")} {
    align-self: center;
    width: 100%;
    padding-bottom: 80%;
    position: relative;

    & > span,
    & > img,
    & > div {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    & > span > svg {
      width: 100%;
      height: 100%;
      & > image {
        width: 100%;
        height: 100%;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.between("md", "lg")} {
    align-self: center;
    width: 50%;
    height: 100%;

    & > img,
    & > div {
      height: 100%;
    }

    & > span {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;

      & > svg {
        height: 100%;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 47%;
    & > span {
      width: 100%;
      display: flex;
      justify-content: center;

      & > svg {
        height: 100%;
      }
    }
  }
`;

export default PortfolioItemRight;
