import styled from "@emotion/styled";

const PortfolioItemContent = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    min-height: 450px;
    gap: 80px;
    padding-top: 15px;
  }

  ${({ theme }) => theme.breakpoints.between("md", "lg")} {
    gap: 40px;
    height: 480px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    height: 603px;
  }
`;

export default PortfolioItemContent;
