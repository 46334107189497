import styled from "@emotion/styled";

const Text = styled.div`
  word-break: break-word;

  h1 {
    margin-bottom: 20px;
    ${({ theme }) => theme.breakpoints.down("lg")} {
      font-size: 45px;
    }

    ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
      font-size: 55px;
    }

    ${({ theme }) => theme.breakpoints.up("xl")} {
      font-size: 65px;
    }
  }

  h2 {
    margin-bottom: 20px;
    ${({ theme }) => theme.breakpoints.down("lg")} {
      font-size: 35px;
    }

    ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
      font-size: 45px;
    }

    ${({ theme }) => theme.breakpoints.up("xl")} {
      font-size: 55px;
    }
  }

  h3 {
    margin-bottom: 20px;
    ${({ theme }) => theme.breakpoints.down("lg")} {
      font-size: 30px;
    }

    ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
      font-size: 40px;
    }

    ${({ theme }) => theme.breakpoints.up("xl")} {
      font-size: 45px;
    }
  }

  hr {
    margin: 20px 0;
  }

  p {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      margin-bottom: 10px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      margin-bottom: 20px;
    }
  }

  li {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      font-size: 20px;
      margin-bottom: 10px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      margin-bottom: 20px;
    }

    ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
      font-size: 25px;
    }

    ${({ theme }) => theme.breakpoints.up("xl")} {
      font-size: 30px;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ol,
  ul {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      padding-left: 20px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      padding-left: 40px;
    }
  }

  a {
    line-height: 1.4;
    transition: 0.3s;
    text-decoration: underline;
    text-underline-offset: 6px;
    &: hover {
      color: ${({ theme }) => theme.palette.section.white};
      text-decoration-color: transparent;
    }
  }
`;

export default Text;
