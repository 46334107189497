import styled from "@emotion/styled";

const DropdownBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 80px;
    gap: 40px;

    svg {
      width: 80px;
      height: 80px;
    }
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-bottom: 190px;
    gap: 30px;

    svg {
      width: 114px;
      height: 114px;
    }
  }
`;

export default DropdownBottom;
