import styled from "@emotion/styled";

const FormWithTextRight = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    display: contents;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    display: flex;
    flex-direction: column;
    width: 47%;
  }
`;

export default FormWithTextRight;
