import Container from "@/components/Container";
import Text from "@/components/Text";

import {
  OnlyTextContent,
  OnlyTextMain,
  OnlyTextTitle,
  OnlyTextWrapper,
} from "@/components/OnlyText/index";

const OnlyText = ({ title, textTop, textBottom, backgroundColor: bgColor }) => {
  return (
    <OnlyTextWrapper bgColor={bgColor}>
      <Container>
        <OnlyTextContent>
          <OnlyTextTitle>{title}</OnlyTextTitle>
          {textTop ? (
            <OnlyTextMain>
              <Text
                dangerouslySetInnerHTML={{
                  __html: textTop.childMarkdownRemark.html,
                }}
              />
            </OnlyTextMain>
          ) : null}
          {textBottom ? (
            <OnlyTextMain>
              <Text
                dangerouslySetInnerHTML={{
                  __html: textBottom.childMarkdownRemark.html,
                }}
              />
            </OnlyTextMain>
          ) : null}
        </OnlyTextContent>
      </Container>
    </OnlyTextWrapper>
  );
};

export default OnlyText;
