import styled from "@emotion/styled";

const HeroContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex-direction: column;
    gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    gap: 80px;
  }
`;

export default HeroContent;
