import { css } from "@emotion/react";
import styled from "@emotion/styled";

const dynamicStyle = ({ variation, theme, order }) => css`
  ${theme.breakpoints.down("lg")} {
    order: ${order};
  }

  ${variation === "small"
    ? "max-width:440px"
    : `
    ${theme.breakpoints.up("lg")} {
      width: 48%;
    }
  `}
`;

const DropdownTable = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 18px 15px 18px;

  ${({ theme }) => theme.breakpoints.down("xl")} {
    margin-top: 20px;
    border: 2px solid ${({ theme }) => theme.palette.text};
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    border: 3px solid ${({ theme }) => theme.palette.text};
    margin-top: 30px;
  }

  ${dynamicStyle}
`;

export default DropdownTable;
