import { css } from "@emotion/react";
import styled from "@emotion/styled";

const dynamicStyle = ({ bgColor }) => css`
  background-color: ${bgColor};
`;

const PortfolioWrapper = styled.section`
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;

  ${dynamicStyle}
`;

export default PortfolioWrapper;
