import styled from "@emotion/styled";

const HeroRight = styled.div`
  display: flex;
  justify-content: center;

  svg,
  span,
  image {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 48%;
  }
`;

export default HeroRight;
