import styled from "@emotion/styled";

const IntroRightBottom = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export default IntroRightBottom;
