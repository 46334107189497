import styled from "@emotion/styled";

const DropdownTitle = styled.h2`
  font-family: "suisse_intl";

  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 32px;
  }

  ${({ theme }) => theme.breakpoints.between("xs", "lg")} {
    font-size: 40px;
  }

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-bottom: 25px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "2lg")} {
    margin-bottom: 26px;
    font-size: 45px;
  }

  ${({ theme }) => theme.breakpoints.up("2lg")} {
    margin-bottom: 33px;
  }

  ${({ theme }) => theme.breakpoints.between("2lg", "xl")} {
    font-size: 73px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    font-size: 78px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    font-size: 88px;
  }
`;

export default DropdownTitle;
