import styled from "@emotion/styled";
import Image from "../Image";

const IntroImage = styled(Image)`
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    height: 584px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    height: 648px;
  }
`;

export default IntroImage;
