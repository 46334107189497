import styled from "@emotion/styled";
import Image from "../Image";

const IntroLogo = styled(Image)`
  position: absolute;
  z-index: 1;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    left: 20px;
    top: 10px;
    width: 256px;
    height: 86px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 242px;
    height: 82px;
  }

  ${({ theme }) => theme.breakpoints.between("lg", "xl")} {
    right: 40px;
    top: 30px;
  }

  ${({ theme }) => theme.breakpoints.between("xl", "2xl")} {
    right: 40px;
    top: 35px;
  }

  ${({ theme }) => theme.breakpoints.up("2xl")} {
    right: 40px;
    top: 33px;
  }
`;

export default IntroLogo;
