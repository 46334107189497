import styled from "@emotion/styled";

const IntroRight = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.buttonBg};

  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 50%;
  }
`;

export default IntroRight;
