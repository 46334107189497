import styled from "@emotion/styled";

const DropdownBlockTitle = styled.h3`
  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 23px;
  }

  ${({ theme }) => theme.breakpoints.between("xs", "xl")} {
    font-size: 30px;
  }

  ${({ theme }) => theme.breakpoints.between("2lg", "xl")} {
    line-height: 35px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 38px;
    line-height: 44px;
  }
`;

export default DropdownBlockTitle;
