import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

import isBrowser from "@/utils/isBrowser";
import Link from "@/components/Link";
import Container from "@/components/Container";
import Image from "@/components/Image";

import {
  PortfolioContent,
  PortfolioTitle,
  PortfolioWrapper,
  PortfolioText,
  PortfolioClose,
  PortfolioBlockImages,
  PortfolioImage,
} from "@/components/Portfolio/index";

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <PortfolioText>{children}</PortfolioText>,
    [INLINES.HYPERLINK]: ({ data: { uri } }, children) => <Link to={uri}>{children}</Link>,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const block = node?.data.target;
      switch (block.__typename) {
        case "ContentfulBlockImage":
          return (
            <PortfolioImage type={block.image.file.contentType}>
              <Image {...block.image} />
              {block.description ? (
                <PortfolioText variation="desc">{block.description.description}</PortfolioText>
              ) : null}
            </PortfolioImage>
          );
        case "ContentfulBlockImagesInRow":
          return (
            <div>
              <PortfolioBlockImages>
                {block.images.map((image) => {
                  return <Image key={image.title} {...image} />;
                })}
              </PortfolioBlockImages>
              {block.description ? (
                <PortfolioText variation="desc">{block.description.description}</PortfolioText>
              ) : null}
            </div>
          );
        default:
          return null;
      }
    },
  },
};

const Portfolio = ({ titleContent, backgroundColor: bgColor, content }) => {
  const parts = (isBrowser() && location.pathname.split("/")) || [];
  const url = (isBrowser() && [...parts.slice(0, -2)].map((item) => item).join("/")) || "";
  return (
    <PortfolioWrapper bgColor={bgColor}>
      {isBrowser() && (
        <PortfolioClose to={url}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.833 28.834">
            <g id="Group_225" data-name="Group 225" transform="translate(-8123.793 23412.208)">
              <line
                id="Line_20"
                data-name="Line 20"
                x2="27.419"
                y2="27.419"
                transform="translate(8124.5 -23411.5)"
                fill="none"
                stroke="#000"
              />
              <path
                id="Path_92"
                data-name="Path 92"
                d="M0,0,27.419,27.419"
                transform="translate(8124.5 -23384.082) rotate(-90)"
                fill="none"
                stroke="#000"
              />
            </g>
          </svg>
        </PortfolioClose>
      )}
      <Container>
        <PortfolioTitle>{titleContent}</PortfolioTitle>
        <PortfolioContent>{renderRichText(content, options)}</PortfolioContent>
      </Container>
    </PortfolioWrapper>
  );
};

export default Portfolio;
