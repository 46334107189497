import FooterBottom from "./FooterBottom";
import FooterBottomNavigation from "./FooterBottomNavigation";
import FooterContent from "./FooterContent";
import FooterInfo from "./FooterInfo";
import FooterInfoBigText from "./FooterInfoBigText";
import FooterInfoSocials from "./FooterInfoSocials";
import FooterWrapper from "./FooterWrapper";

export {
  FooterBottom,
  FooterBottomNavigation,
  FooterContent,
  FooterInfo,
  FooterInfoBigText,
  FooterInfoSocials,
  FooterWrapper,
};
