import { Helmet } from "react-helmet";
import React from "react";
import CookieConsent from "react-cookie-consent";
import { Global, ThemeProvider } from "@emotion/react";

import isBrowser from "@/utils/isBrowser";
import Seo from "@/components/Seo";
import theme from "@/theme";
import * as Sections from "@/sections";
import reboot from "@/styles/reboot";
import font from "@/styles/font";

import "destyle.css";

const STORAGE_NAME = "@ScrollWindowPosition";

const Template = ({ pageContext: { baseUrl, seo, sections, title, logo, urlsArray, cookies } }) => {
  React.useEffect(() => {
    if (isBrowser()) {
      const scrollOffset = sessionStorage.getItem(
        `${STORAGE_NAME}-${isBrowser() ? window.location.pathname : ""}`,
      );
      if (scrollOffset) {
        window.scrollTo(0, +scrollOffset);
        sessionStorage.removeItem(`${STORAGE_NAME}-${isBrowser() ? window.location.pathname : ""}`);
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Seo {...seo} />
      <Helmet defer={false} />
      <Global styles={[reboot, font]} />
      {isBrowser() && (
        <CookieConsent
          location="bottom"
          disableStyles
          containerClasses="cookies"
          buttonClasses="cookies-button"
          style={{
            backgroundColor:
              location.href.indexOf("/en") > 0
                ? cookies[1].backgroundColor
                : cookies[0].backgroundColor,
          }}
          buttonText={
            location.href.indexOf("/en") > 0 ? cookies[1].buttonText : cookies[0].buttonText
          }
        >
          <p>
            {location.href.indexOf("/en") > 0 ? cookies[1].text : cookies[0].text}
            <a href={location.href.indexOf("/en") > 0 ? cookies[1].link : cookies[0].link}>
              {location.href.indexOf("/en") > 0
                ? ` ${cookies[1].linkText}.`
                : ` ${cookies[0].linkText}.`}
            </a>
          </p>
        </CookieConsent>
      )}
      {sections.map(({ id, component, ...props }) => {
        const Component = Sections[component];
        if (!Component)
          return (
            <div key={id}>
              <div>{component}</div>
            </div>
          );
        if (component === "Hero") {
          return (
            <Component
              key={id}
              // active={activeDropdown === "hero"}
              baseUrl={baseUrl}
              {...props}
            />
          );
        }

        if (component === "Header") {
          return (
            <Component
              urlsArray={urlsArray}
              key={id}
              pageTitle={title}
              logo={logo}
              baseUrl={baseUrl}
              {...props}
            />
          );
        }

        if (component === "Intro" || component === "Footer") {
          return <Component urlsArray={urlsArray} key={id} baseUrl={baseUrl} {...props} />;
        }

        if (component === "Dropdown") {
          return <Component key={id} baseUrl={baseUrl} {...props} />;
        }
        // eslint-disable-next-line no-console
        console.log(props);
        return <Component key={id} baseUrl={baseUrl} {...props} />;
      })}
    </ThemeProvider>
  );
};

export default Template;
