import styled from "@emotion/styled";

const HeroWrapper = styled.section`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: 0.5s;
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${({ active, theme }) =>
    active
      ? `
  max-height: 5000px;
  ${theme.breakpoints.down("lg")} {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  ${theme.breakpoints.up("lg")} {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  `
      : "max-height: 0;"};
`;

export default HeroWrapper;
