import isBrowser from "@/utils/isBrowser";
import Container from "@/components/Container";
import Image from "@/components/Image";
import Link from "@/components/Link";
import { Languages, LanguagesItem } from "@/components/LanguagesBlock/index";

import {
  FooterBottom,
  FooterBottomNavigation,
  FooterContent,
  FooterInfo,
  FooterInfoBigText,
  FooterInfoSocials,
  FooterWrapper,
} from "@/components/Footer/index";

const Footer = ({
  copyrightText,
  backgroundColor,
  info: {
    address: { address },
    email,
    phone,
    socials,
  },
  links,
  urlsArray,
}) => (
  <FooterWrapper backgroundColor={backgroundColor}>
    <Container disablePaddings>
      <FooterContent>
        <FooterInfo>
          <FooterInfoSocials>
            {socials.map(({ id, icon, url }) => {
              return (
                <Link ariaLabel={url} to={url} key={id}>
                  <Image {...icon} />
                </Link>
              );
            })}
          </FooterInfoSocials>
          <FooterInfoBigText>
            <a href={`mailto:${email}`}>{email}</a>
            <a href={`tel:${phone.replace(/[^0-9]/g, "")}`}>{phone}</a>
          </FooterInfoBigText>
          <pre>{address}</pre>
        </FooterInfo>
        <FooterBottom>
          <FooterBottomNavigation>
            {isBrowser() && (
              <Languages variation="footer">
                {Object.entries(urlsArray).map((el) =>
                  urlsArray.de && urlsArray.en ? (
                    <LanguagesItem
                      key={el[0]}
                      disablePadding
                      active={location.pathname === `${el[1] === "/" ? el[1] : `${el[1]}/`}`}
                      to={el[1]}
                    >
                      {el[0]}
                    </LanguagesItem>
                  ) : (
                    ""
                  ),
                )}
              </Languages>
            )}
            {links.map(({ id, text, url }) => (
              <Link key={id} to={url}>
                {text}
              </Link>
            ))}
          </FooterBottomNavigation>
          <p>{copyrightText}</p>
        </FooterBottom>
      </FooterContent>
    </Container>
  </FooterWrapper>
);

export default Footer;
