import styled from "@emotion/styled";

const PortfolioContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down("lg")} {
    gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 946px;
    gap: 40px;
  }
`;

export default PortfolioContent;
