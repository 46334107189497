import { Link as GatsbyLink } from "gatsby";

const Link = ({ to, children, onClick, className, ariaLabel, ...props }) => {
  if (
    /^(http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?$/.test(to) ||
    // eslint-disable-next-line no-useless-escape
    /^mailto:([^?]*)$/.test(to)
  )
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        href={to || "/"}
        rel="noopener noreferrer"
        className={className}
        aria-label={ariaLabel}
        target="_blank"
        {...props}
      >
        {children}
      </a>
    );
  return (
    <GatsbyLink to={to || "/"} onClick={onClick} className={className} aria-label={ariaLabel}>
      {children}
    </GatsbyLink>
  );
};

export default Link;
