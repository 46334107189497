import { css } from "@emotion/react";
import styled from "@emotion/styled";

const dynamicStyle = ({ bgColor }) => css`
  background-color: ${bgColor};
`;

const FormWithTextWrapper = styled.section`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  ${dynamicStyle}
`;

export default FormWithTextWrapper;
