import Container from "@/components/Container";
import Image from "@/components/Image";
import { navigate } from "gatsby";

import {
  PortfolioItemLeft,
  PortfolioItemRight,
  PortfolioItemTitle,
  PortfolioItemWrapper,
  PortfolioItemContent,
} from "@/components/PortfolioItem/index";
import isBrowser from "@/utils/isBrowser";

const STORAGE_NAME = "@ScrollWindowPosition";

const PortfolioItem = ({
  imagePreview: { image },
  titlePreview,
  subtitlePreview,
  backgroundColor: bgColor,
  url,
}) => {
  return (
    <PortfolioItemWrapper
      onClick={() => {
        if (isBrowser())
          sessionStorage.setItem(
            `${STORAGE_NAME}-${isBrowser() ? window.location.pathname : ""}`,
            window.scrollY,
          );
        navigate(url);
      }}
      bgColor={bgColor}
    >
      <Container disablePaddings>
        <PortfolioItemContent bgColor={bgColor}>
          <PortfolioItemLeft>
            <PortfolioItemTitle>{titlePreview}</PortfolioItemTitle>
            <p>{subtitlePreview}</p>
          </PortfolioItemLeft>
          <PortfolioItemRight>
            <Image {...image} />
          </PortfolioItemRight>
        </PortfolioItemContent>
      </Container>
    </PortfolioItemWrapper>
  );
};

export default PortfolioItem;
