import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Render inline SVG with fallback non-svg images
const Image = ({ svg, file, title, gatsbyImageData, useImage, ...props }) => {
  // Inlined SVGs
  if (file?.contentType === "image/svg+xml" && svg?.originalContent && !useImage) {
    return <span dangerouslySetInnerHTML={{ __html: svg?.originalContent }} {...props} />;
  }
  // SVGs that can/should not be inlined of gif's
  if (file?.contentType === "image/svg+xml" || file?.contentType === "image/gif")
    return <img src={file.url} alt={title} {...props} />;

  // Non SVG images
  if (gatsbyImageData)
    return <GatsbyImage image={getImage({ gatsbyImageData })} alt={title} {...props} />;

  return null;
};

export default Image;
