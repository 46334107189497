import styled from "@emotion/styled";

const FooterBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    flex-direction: column;
    align-items: start;
    gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    align-items: center;
  }
`;

export default FooterBottom;
