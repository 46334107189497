import { css } from "@emotion/react";

const reboot = (theme) => css`
  html {
    min-height: 100vh;
  }

  body,
  pre {
    font-family: ${theme.typography.fontFamily} !important;
  }

  a[href],
  button,
  label {
    -webkit-tap-highlight-color: transparent;
  }

  .cookies {
    width: 100%;
    color: ${theme.palette.text};
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: auto;
    z-index: 1;

    ${theme.breakpoints.down("lg")} {
      font-size: 15px;
    }

    ${theme.breakpoints.up("lg")} {
      font-size: 20px;
    }

    &-button {
      display: flex;
      margin-left: auto;
      background-color: transparent;
      border: 2px solid ${theme.palette.text};
      padding: 12px 20px;
    }

    & a:hover {
      text-decoration-line: underline;
      text-underline-position: under;
      text-decoration-color: ${theme.palette.text};
    }
  }
`;

export default reboot;
