import styled from "@emotion/styled";

const DropdownTableHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 15px;
  gap: 30px;

  ${({ theme }) => theme.breakpoints.between("xs", "xl")} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.down("xl")} {
    border-bottom: 2px solid ${({ theme }) => theme.palette.text};
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    font-size: 26px;
    height: 310px;
    border-bottom: 3px solid ${({ theme }) => theme.palette.text};
  }
`;

export default DropdownTableHead;
