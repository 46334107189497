import styled from "@emotion/styled";

const IntroNavigation = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    & > a:first-of-type {
      display: none;
    }
  }
`;

export default IntroNavigation;
