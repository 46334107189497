import styled from "@emotion/styled";

const FooterInfoSocials = styled.div`
  display: flex;
  gap: 20px;

  svg {
    ${({ theme }) => theme.breakpoints.down("lg")} {
      width: 29px;
      height: 29px;
    }

    ${({ theme }) => theme.breakpoints.up("lg")} {
      width: 55px;
      height: 55px;
    }
  }
`;

export default FooterInfoSocials;
