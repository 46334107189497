import styled from "@emotion/styled";

const DropdownBlockRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 100%;
    gap: 30px;
    display: contents;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    gap: 85px;
    width: 48%;
  }
`;

export default DropdownBlockRight;
