import styled from "@emotion/styled";
import Link from "../Link";

const IntroLink = styled(Link)`
  max-width: fit-content;
  background-color: ${({ theme }) => theme.palette.section.rose};
  margin-bottom: 60px;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    margin-top: 60px;
    padding: 17px 30px;
    font-size: 32px;
    border: 2px solid ${({ theme }) => theme.palette.text};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-top: 40px;
    padding: 20px 45px;
    font-size: 42px;
    border: 3px solid ${({ theme }) => theme.palette.text};
  }
`;

export default IntroLink;
