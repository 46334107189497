import styled from "@emotion/styled";

const IntroText = styled.div`
  ${({ theme }) => theme.breakpoints.down("lg")} {
    font-size: 25px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: 34px;
  }
`;

export default IntroText;
